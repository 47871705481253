import { Fragment } from 'react'
import { PageHeader } from './Index'
import Head from 'next/head'
import { useLanguage } from '@/hooks/Contexts'

export function AppLayout(props: { title?: string; headerVisible?: boolean; children: any; outerClass?: string; headerClass?: string; innerClass?: string }) {
  const { navigation: strings } = useLanguage()
  const { title, headerVisible = true } = props
  const titleStr = (strings[title] ?? title) + ' - ' + strings.app_name

  return (
    <Fragment>
      <Head>
        <title>{titleStr}</title>
        <meta property='og:title' content={titleStr} key='og:title' />
        <meta name='twitter:title' content={titleStr} />
        <link rel='icon' href='/assets/favicons/favicon.ico' />
      </Head>
      {title && headerVisible && <PageHeader title={strings[title] ?? title} additionalClasses={props.headerClass ?? ''} />}
      <div className={`${props.outerClass ?? ''} ${!headerVisible ? 'py-4' : ''} `}>
        <div className={`main-container animate-fade-in-down flex-col pb-4 ${props.innerClass ?? ''}`}>{props.children}</div>
      </div>
    </Fragment>
  )
}
