/*
|--------------------------------------------------------------------------
| 404 Page.
|--------------------------------------------------------------------------
|
| The view that gets rendered when a user tries to visit a route that has
| no matching file in your /pages directory.
| If the user is authenticated, a link to the user home route (defined in your
| .env.local) will be displayed. Otherwise, a link to the homepage will be
| displayed.
|
*/
import { AppLayout } from '@/components/Layouts/AppLayout'
import { useLanguage } from '@/hooks/Contexts'

function NotFoundPage() {
  /**
   * Determine the link location that the link
   * on the page will lead to. If the user is
   * authenticated, it will be the user home route.
   * Otherwise it will be the homepage.
   */
  // const linkLocation = props.isAuthenticated ? process.env.NEXT_PUBLIC_USER_HOME_ROUTE : '/'

  const { notFound: strings } = useLanguage()

  return (
    <AppLayout title={strings['404_error']}>
      <div className='flex flex-col items-center gap-x-4 bg-blue-rgba2 p-2 md:flex-row-reverse md:p-4'>
        <img src='/assets/unsplash_heNwUmEtZzo.png' className='w-full flex-1 rounded-lg md:w-1/2' />
        <div className='mt-4 flex-1'>
          <p className='mb-4 text-base font-semibold md:text-lg'>{strings.looking_for_competition}</p>
          <p className='text-sm md:text-base'>{strings.paragraph}</p>
        </div>
      </div>
    </AppLayout>
  )
}

export default NotFoundPage
